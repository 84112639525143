<script>
import BaseInput from '@/components/BaseInput.vue';
import BaseTextarea from '@/components/BaseTextarea.vue';

export default {
  name: 'ContactMe',

  components: {
    BaseInput,
    BaseTextarea,
  },

  data() {
    return {
      error: '',
      success: '',

      form: {
        name: {
          value: '',
          validate: (val) => val.length >= 4,
        },
        email: {
          value: '',
          validate: (val) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            val,
          ),
        },
        phone: {
          value: '',
          validate: () => true,
        },
        message: {
          value: '',
          validate: (val) => val.length > 5,
        },
      },
    };
  },

  mounted() {
    $('.background-image-holder').each(function () {
      const imgSrc = $(this).children('img').attr('src');
      $(this).css('background', `url("${imgSrc}")`);
      $(this).children('img').hide();
      $(this).css('background-position', 'initial');
    });
  },

  methods: {
    postForm() {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: '/api/contactform',
          method: 'POST',
          contetType: 'application/json',
        })
          .done(resolve)
          .fail(reject);
      });
    },

    submitForm() {
      this.success = '';
      this.error = '';

      if (this.validate()) {
        const payload = this.form;
        this.postForm(payload)
          .then(() => (this.success = 'Message successfully sent.'))
          .catch(
            () => (this.error = 'There was a problem processing your request. Please try again later'),
          );
      } else {
        this.error = 'Please fill out all required fields.';
      }
    },

    validate() {
      return Object.keys(this.form).every((key) => this.form[key].validate(this.form[key].value));
    },
  },
};
</script>
<template>
  <teleport to="head">
    <title>
      Kristen Stuecher - Contact Us
    </title>
  </teleport>
  <section class="page-title page-title-1 image-bg overlay">
    <div class="background-image-holder">
      <img
        alt="Background Image"
        class="background-image"
        src="@/index/assets/img/small1.jpg"
      />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h1 class="uppercase mb0">Contact Us</h1>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-md-5">
          <h4 class="uppercase">Get In Touch</h4>
          <p class="lead">
            I love where I live and you should too! Let me help you reach your dreams!
          </p>
          <hr />
          <p>
            891 Beach Street
            <br />
            San Francisco, CA 94109
          </p>
          <hr />
          <p>
            <strong>E: </strong>
            <a href="mailto:kristen.stuecher@compass.com"
              >kristen.stuecher@compass.com</a
            >
            <br />
            <strong>P:</strong> <a href="tel:415-948-4888">415-948-4888</a>
            <br />
          </p>
        </div>
        <div class="col-sm-6 col-md-5 col-md-offset-1">
          <form @submit.prevent="submitForm" class="form-email">
            <BaseInput
              v-model="form.name.value"
              type="text"
              class="validate-required"
              placeholder="Your Name *"
            />
            <BaseInput
              v-model="form.email.value"
              type="text"
              class="validate-required validate-email"
              placeholder="Email Address *"
            />
            <BaseInput
              v-model="form.phone.value"
              type="text"
              class="validate-required validate-email"
              placeholder="Phone"
            />
            <BaseTextarea
              v-model="form.message.value"
              class="validate-required mb0"
              rows="4"
              placeholder="Message *"
            ></BaseTextarea>
            <div class="mb16">
              <small>* Required fields</small>
            </div>

            <button class="btn bg-dark" type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
